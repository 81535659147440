// slices/resetSlice.ts
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const resetSlice = createSlice({
    name: "reset",
    initialState,
    reducers: {
        resetState: () => initialState,
    },
});

export const { resetState } = resetSlice.actions;

export default resetSlice.reducer;
