import { Media, MediaLeft, Avatar, AvatarInitial } from "@doar/components";
import { StyledMediaBody, StyledStrong, StyledText, StyledSpan } from "./style";

interface NotificationInterface {
    name: string;
    message: string;
    time: string;
}

const DropdownItem = (props: any) => {
    const { notification } = props;
    return (
        <Media>
            {/* <MediaLeft>
                <Avatar size="sm" shape="circle" status="online">
                    <AvatarInitial>df</AvatarInitial>
                </Avatar>
            </MediaLeft> */}
            <StyledMediaBody>
                <StyledStrong>{notification.name}</StyledStrong>
                <StyledText>{notification.message}</StyledText>
                <StyledSpan>{notification.time}</StyledSpan>
            </StyledMediaBody>
        </Media>
    );
};

export default DropdownItem;
