import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endpoints, fetchWrapper } from "../../util/api";
import { getAccessToken } from "../../util/auth";

export interface NewsState {
    newsList: NewsDetails[];
    filteredNewsList: NewsDetails[];
    // exchangeStatuses: ExchangeStatusData[];
    selectedNewsId: string;
    selectedNewsInsId: string;
}

export type NewsDetails = {
    _id: string;
    nws_title: string;
    nws_txt: string;
    nws_source: string;
    nws_url: string;
    nws_bannerurl: string;
    nws_posteddt: string;
    nws_mktid: string;
    nws_insid: string;
    nws_priority: string;
    nws_tags: string;
    nws_isactive: boolean;
    nws_timestamp: string;
    nws_topics: {
        topic: string;
        relevance_score: string;
    }[];
    nws_ovSentimentscore: string;
    nws_ovSentimentlabel: string;
    nws_tickers: {
        ticker: string;
        relevance_score: string;
        ticker_sentiment_score: string;
        ticker_sentiment_label: string;
    }[];
    nws_sf1: string | null;
    __v: number;
};

export interface ExchangeStatusData {
    _id: string;
    exc_name: string;
    exc_desc: string;
    exc_location: string;
    exc_currency: string | null;
    exc_opentime: string | null;
    exc_closetime: string | null;
    exc_tzone: string | null;
    exc_currstatus: string;
    exc_mktype: string;
    exc_prmexchanges: string;
    exc_localopen: string;
    exc_localclose: string;
    exc_updatetime: string;
    __v: number;
}

const initialState: NewsState = {
    newsList: [],
    filteredNewsList: [],
    selectedNewsId: "",
    selectedNewsInsId: "",
};

export const fetchAllNewsData: any = createAsyncThunk(
    "news/fetchAllNewsData",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.all_news_data, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: NewsDetails[] = await response.json();
            return data;
        }
    }
);

export const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        setAllNewsDataToStore: (
            state,
            action: PayloadAction<NewsDetails[]>
        ) => {
            state.newsList = action.payload;
        },
        setFilteredNewsDataToStore: (
            state,
            action: PayloadAction<NewsDetails[]>
        ) => {
            state.filteredNewsList = action.payload;
        },
        setSelectedNewsId: (state, action: PayloadAction<string>) => {
            state.selectedNewsId = action.payload;
        },
        setSelectedNewsInsId: (state, action: PayloadAction<string>) => {
            state.selectedNewsInsId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllNewsData.fulfilled, (state, action) => {
            if (action.payload?.length > 0) {
                state.newsList = action.payload;
                state.filteredNewsList = action.payload;
            }
        });
    },
});

export const {
    setAllNewsDataToStore,
    setSelectedNewsId,
    setFilteredNewsDataToStore,
    setSelectedNewsInsId,
} = newsSlice.actions;
export default newsSlice.reducer;
