import {
    Edit3,
    User,
    HelpCircle,
    LifeBuoy,
    Settings,
    LogOut,
} from "react-feather";
import {
    DropdownToggle,
    Dropdown,
    Avatar,
    AvatarInitial,
} from "@doar/components";
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import {
    ACCOUNT_SETTINGS_HIDE_PAGES,
    PROFILE_ICON_HIDE_PAGES,
} from "../../../util/consts";

const ProfileDropdown = () => {
    const location = useLocation();
    const userData = useSelector((state: RootState) => state.user.userData);
    return (
        <Dropdown direction="down" className="dropdown-profile">
            {!PROFILE_ICON_HIDE_PAGES.some((page) =>
                location.pathname.includes(page)
            ) && (
                <DropdownToggle variant="texted">
                    <StyledAvatar size="sm" shape="circle">
                        <AvatarInitial>
                            {" "}
                            {userData.cus_name
                                ? userData.cus_name
                                      .split(" ")
                                      ?.map((name) => name[0])
                                      ?.join("")
                                      ?.toUpperCase()
                                : "SM"}
                        </AvatarInitial>
                    </StyledAvatar>
                </DropdownToggle>
            )}
            <StyledDropMenu>
                <Avatar size="lg" shape="circle">
                    <AvatarInitial>
                        {userData.cus_name
                            ? userData.cus_name
                                  .split(" ")
                                  ?.map((name) => name[0])
                                  ?.join("")
                                  ?.toUpperCase()
                            : "SM"}
                    </AvatarInitial>
                </Avatar>
                <StyledAuthorName>
                    {" "}
                    {userData ? userData.cus_name : "NULL"}
                </StyledAuthorName>
                <StyledAuthorRole>
                    {" "}
                    {userData ? userData.cus_email : "NULL"}
                </StyledAuthorRole>
                {/* <StyledAuthorRole>Administrator</StyledAuthorRole> */}
                {/* <StyledDropItem path="/profile-view">
                    <Edit3 size="24" />
                    Edit Profile
                </StyledDropItem>
                <StyledDropItem path="/profile-view" mt="10px">
                    <User size="24" />
                    View Profile
                </StyledDropItem> */}
                <StyledDivider />
                {/* <StyledDropItem
                    path="https://hasthemes.com/contact-us/"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Help Center
                </StyledDropItem>
                <StyledDropItem path="/" mt="10px">
                    <LifeBuoy size="24" />
                    Forum
                </StyledDropItem> */}
                {ACCOUNT_SETTINGS_HIDE_PAGES.some((page) =>
                    location.pathname.includes(page)
                ) ? (
                    <></>
                ) : (
                    <StyledDropItem path="/profile-view" mt="10px">
                        <Settings size="24" />
                        Account Settings
                    </StyledDropItem>
                )}
                {/* <StyledDropItem path="/profile-view" mt="10px">
                    <Settings size="24" />
                    Privacy Settings
                </StyledDropItem> */}
                <StyledDropItem path="/signout" mt="10px">
                    <LogOut size="24" />
                    Sign Out
                </StyledDropItem>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;
