/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable  @typescript-eslint/no-floating-promises */

import { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, IAuthContext } from "../contexts/AuthContext";
import {
    TUserAuth,
    TUserData,
    clearUserData,
    getAccessToken,
    getRefreshToken,
    setUserAuthData,
    setUserData,
    updateAccessToken,
} from "../util/auth";
import { endpoints, fetchWrapper } from "../util/api";
import {
    fetchCustomerData,
    setUserDataToStore,
} from "../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { resetState } from "../redux/slices/resetSlice";

interface LocationState {
    from: {
        pathname: string;
    };
}

const BEFORE_ACCESS_TOKEN_EXPIRES_TIME = 1000 * 60 * 8; // 8 min

interface AuthProviderProps {
    children: ReactElement;
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const localAccessToken = getAccessToken() || null;
    const refreshToken = getRefreshToken() || null;
    const navigate = useNavigate();
    const location = useLocation();
    const [isFirstMounted, setIsFirstMounted] = useState(false);
    const dispatch = useDispatch();
    const loggedInUser = useSelector((state: RootState) => state.user.userData);

    useEffect(() => {
        if (!localAccessToken && !refreshToken) {
            setIsFirstMounted(true);
        } else {
            setIsFirstMounted(false);
        }
    }, [localAccessToken, refreshToken]);

    // async function handleGetUserData() {
    //     const accessToken: string = getAccessToken();
    //     const response = await fetchWrapper(endpoints.get_customer_data, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer " + accessToken,
    //         },
    //     });
    //     if (response.ok) {
    //         const res = await response.json();
    //         if (res) {
    //             setUserData(res);
    //             dispatch(setUserDataToStore(res));
    //         }
    //     }
    // }

    const handleLogin = (userData: Partial<TUserAuth>) => {
        setUserAuthData(userData);
        console.log("calling customer data call - handleLogin");
        dispatch(fetchCustomerData());

        // user data fetch initial
        // const callUserDataApiInitial = async () => {
        //     const accessToken: string = getAccessToken();
        //     const response = await fetchWrapper(endpoints.get_customer_data, {
        //         method: "GET",
        //         headers: {
        //             "Content-Type": "application/json",
        //             Authorization: "Bearer " + accessToken,
        //         },
        //     });
        //     if (response.ok) {
        //         const res: TUserData = await response.json();
        //         if (res) {
        //             setUserData(res);
        //             dispatch(setUserDataToStore(res));

        //             if (res?.cus_ispaid) {
        //                 const origin =
        //                     (location.state as LocationState)?.from?.pathname ||
        //                     "/dashboard";
        //                 navigate(origin);
        //             } else {
        //                 const pricingPg = "/pricing";
        //                 navigate(pricingPg);
        //             }
        //         }
        //     }
        // };
        // callUserDataApiInitial();
    };

    const handleLogout = async () => {
        clearUserData();
        // Also remove user's refresh token from server
        await fetchWrapper(endpoints.logout, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: { token: refreshToken },
        });

        // Dispatch the reset action
        dispatch(resetState());

        navigate("/signin", { replace: true });
    };

    async function updateRefreshtoken() {
        const response = await fetchWrapper(endpoints.token_refresh, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: { token: refreshToken },
        });

        if (response.ok) {
            const res: TUserAuth = await response.json();
            updateAccessToken(res?.accessToken);
        } else {
            clearUserData();
            navigate("/signin");
            window.location.reload();
        }
        if (isFirstMounted) {
            setIsFirstMounted(false);
        }
    }

    useEffect(() => {
        const callUserDataApi = async () => {
            await updateRefreshtoken();
            // await handleGetUserData();
            console.log(
                "calling customer data call - callUserDataApi - Auth Provider"
            );
            dispatch(fetchCustomerData());
        };
        if (refreshToken) {
            // Check on the first render
            if (isFirstMounted) {
                callUserDataApi();
            }

            // Keep checking after a certain time
            const intervalId = setInterval(() => {
                updateRefreshtoken();
            }, BEFORE_ACCESS_TOKEN_EXPIRES_TIME);
            return () => clearInterval(intervalId);
        }
        return undefined;
    }, [localAccessToken]);

    const value = useMemo(
        () => ({
            token: localAccessToken,
            onLogin: handleLogin,
            onLogout: handleLogout,
        }),
        [localAccessToken]
    ) as IAuthContext;

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
