import { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout from "./layouts";
import Layout02 from "./layouts/layout-02";
import ProtectedRoute from "./routes/ProtectedRoute";
import AuthProvider from "./routes/AuthProvider";
import DashboardFour from "./pages/dashboard-four";

// Classic Pages

const DashboardOne = lazy(() => import("./pages/dashboard-one"));
const DashboardTwo = lazy(() => import("./pages/dashboard-two"));
const DashboardThree = lazy(() => import("./pages/dashboard-three"));
// const DashboardFour = lazy(() => import("./pages/dashboard-four"));
// const DashboardFive = lazy(() => import("./pages/dashboard-five"));
// const DashboardSix = lazy(() => import("./pages/dashboard-six"));
const SignIn = lazy(() => import("./pages/signin"));
const SignOut = lazy(() => import("./pages/signout"));
const SignUp = lazy(() => import("./pages/signup"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
// const Error500 = lazy(() => import("./pages/error-500"));
// const Error503 = lazy(() => import("./pages/error-503"));
// const Error505 = lazy(() => import("./pages/error-505"));
const ProfileView = lazy(() => import("./pages/profile-view"));
const Connections = lazy(() => import("./pages/connections"));
const Groups = lazy(() => import("./pages/groups"));
const Events = lazy(() => import("./pages/events"));
const Timeline = lazy(() => import("./pages/timeline"));
const Pricing = lazy(() => import("./pages/pricing"));
const Payment = lazy(() => import("./pages/payment"));
const HelpCenter = lazy(() => import("./pages/help-center"));
const Invoice = lazy(() => import("./pages/invoice"));
const Calendar = lazy(() => import("./pages/apps/calendar"));
const Chat = lazy(() => import("./pages/apps/chat"));
const Contacts = lazy(() => import("./pages/apps/contacts"));
const FileManager = lazy(() => import("./pages/apps/file-manager"));
const Mail = lazy(() => import("./pages/apps/mail"));
const News = lazy(() => import("./pages/apps/news"));

const Charts = lazy(() => import("./pages/charts"));
const PasswordReset = lazy(() => import("./pages/password-reset"));
const EmailConfirm = lazy(() => import("./pages/email-confirm"));
const InstrumentSelection = lazy(() => import("./pages/instrument-selection"));

// Classic Plus Pages

// const ClassicPlusDashboardOne = lazy(
//     () => import("./pages/classic-plus/dashboard-one")
// );
// const ClassicPlusDashboardTwo = lazy(
//     () => import("./pages/classic-plus/dashboard-two")
// );
// const ClassicPlusDashboardThree = lazy(
//     () => import("./pages/classic-plus/dashboard-three")
// );
// const ClassicPlusDashboardFour = lazy(
//     () => import("./pages/classic-plus/dashboard-four")
// );
// const ClassicPlusDashboardFive = lazy(
//     () => import("./pages/classic-plus/dashboard-five")
// );
// const ClassicPlusSignIn = lazy(() => import("./pages/classic-plus/signin"));
// const ClassicPlusSignUp = lazy(() => import("./pages/classic-plus/signup"));
// const ClassicPlusVerifyAccount = lazy(
//     () => import("./pages/classic-plus/verify-account")
// );
// const ClassicPlusForgotPassword = lazy(
//     () => import("./pages/classic-plus/forgot-password")
// );
// const ClassicPlusErrorNotFound = lazy(
//     () => import("./pages/classic-plus/error-404")
// );
// const ClassicPlusError500 = lazy(
//     () => import("./pages/classic-plus/error-500")
// );
// const ClassicPlusError503 = lazy(
//     () => import("./pages/classic-plus/error-503")
// );
// const ClassicPlusError505 = lazy(
//     () => import("./pages/classic-plus/error-505")
// );

// const ClassicPlusProfileView = lazy(
//     () => import("./pages/classic-plus/profile-view")
// );
// const ClassicPlusConnections = lazy(
//     () => import("./pages/classic-plus/connections")
// );
// const ClassicPlusGroups = lazy(() => import("./pages/classic-plus/groups"));
// const ClassicPlusEvents = lazy(() => import("./pages/classic-plus/events"));
// const ClassicPlusTimeline = lazy(() => import("./pages/classic-plus/timeline"));
// const ClassicPlusPricing = lazy(() => import("./pages/classic-plus/pricing"));
// const ClassicPlusHelpCenter = lazy(
//     () => import("./pages/classic-plus/help-center")
// );
// const ClassicPlusInvoice = lazy(() => import("./pages/classic-plus/invoice"));
// const ClassicPlusCalendar = lazy(
//     () => import("./pages/classic-plus/apps/calendar")
// );
// const ClassicPlusChat = lazy(() => import("./pages/classic-plus/apps/chat"));
// const ClassicPlusContacts = lazy(
//     () => import("./pages/classic-plus/apps/contacts")
// );
// const ClassicPlusFileManager = lazy(
//     () => import("./pages/classic-plus/apps/file-manager")
// );
// const ClassicPlusMail = lazy(() => import("./pages/classic-plus/apps/mail"));

const App = () => {
    return (
        <>
            <Router>
                <AuthProvider>
                    <Suspense fallback={<Preloader />}>
                        <Routes>
                            {/* Classic Routes */}

                            <Route
                                element={
                                    <Layout sidebarLayout={1}>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                <Route path="/signin" element={<SignIn />} />
                                <Route path="/signup" element={<SignUp />} />
                            </Route>

                            <Route
                                element={
                                    <Layout>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                {/* Index route */}
                                <Route path="/" element={<SignIn />} />

                                <Route path="/signout" element={<SignOut />} />

                                <Route
                                    path="/confirm-email"
                                    element={<EmailConfirm />}
                                />
                                <Route
                                    path="/forgot-password"
                                    element={<ForgotPassword />}
                                />
                                <Route
                                    path="/error-404"
                                    element={<ErrorNotFound />}
                                />

                                {/* Protected Routes */}

                                <Route
                                    path="/dashboard-one"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardOne />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/dashboard"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardOne />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/instrumentselection"
                                    element={
                                        <ProtectedRoute>
                                            <InstrumentSelection />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/dashboard-two"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardTwo />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/technical-analysis"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardTwo />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/dashboard-three"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardThree />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/predictive-analysis"
                                    element={
                                        <ProtectedRoute>
                                            <DashboardFour />
                                        </ProtectedRoute>
                                    }
                                />

                                {/* User Routes */}
                                <Route
                                    path="/profile-view"
                                    element={
                                        <ProtectedRoute>
                                            <ProfileView />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/connections"
                                    element={
                                        <ProtectedRoute>
                                            <Connections />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/groups"
                                    element={
                                        <ProtectedRoute>
                                            <Groups />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/events"
                                    element={
                                        <ProtectedRoute>
                                            <Events />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/charts"
                                    element={
                                        <ProtectedRoute>
                                            <Charts />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/news"
                                    element={
                                        <ProtectedRoute>
                                            <News />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/timeline"
                                    element={
                                        <ProtectedRoute>
                                            <Timeline />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/pricing"
                                    element={
                                        <ProtectedRoute>
                                            <Pricing />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/password-reset"
                                    element={<PasswordReset />}
                                />

                                <Route
                                    path="/help-center"
                                    element={
                                        <ProtectedRoute>
                                            <HelpCenter />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/payment"
                                    element={
                                        <ProtectedRoute>
                                            <Payment />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/verify-account"
                                    element={
                                        <ProtectedRoute>
                                            <VerifyAccount />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route
                                element={
                                    <Layout
                                        hasSidebar
                                        hideFooter
                                        sidebarLayout={2}
                                    >
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                <Route
                                    path="/apps/contacts"
                                    element={
                                        <ProtectedRoute>
                                            <Contacts />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/apps/chat"
                                    element={
                                        <ProtectedRoute>
                                            <Chat />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            {/* App Route With Sidebar*/}
                            <Route
                                element={
                                    <Layout hasSidebar hideFooter>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                <Route
                                    path="/apps/calendar"
                                    element={
                                        <ProtectedRoute>
                                            <Calendar />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/apps/file-manager"
                                    element={
                                        <ProtectedRoute>
                                            <FileManager />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/apps/mail"
                                    element={
                                        <ProtectedRoute>
                                            <Mail />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route
                                element={
                                    <Layout02>
                                        <Outlet />
                                    </Layout02>
                                }
                            ></Route>

                            {/* 404 Page Route */}
                            <Route
                                element={
                                    <Layout>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                <Route path="*" element={<ErrorNotFound />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </AuthProvider>
            </Router>
        </>
    );
};

export default App;
