import { ThemeProvider, themes } from "@doar/shared/styled";
import { GlobalStyle } from "@doar/shared/css";
import { TTheme } from "@doar/shared/types";
import { useAppDispatch, useAppSelector } from "../hooks";
import { toggleTheme } from "../slices/theme";
import SettingsCard from "../../components/settings";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Theme = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    const userData = useSelector((state: RootState) => state?.user?.userData);
    const { theme } = useAppSelector((state) => state.theme);

    const themeHandler = (curTheme: TTheme) => {
        dispatch(toggleTheme({ theme: curTheme }));
    };
    useEffect(() => {
        dispatch(toggleTheme({ theme: theme }));
    }, []);

    return (
        <ThemeProvider theme={themes[theme]}>
            <GlobalStyle />
            {userData?.cus_ispaid && (
                <SettingsCard themeHandler={themeHandler} curTheme={theme} />
            )}
            {children}
        </ThemeProvider>
    );
};

export default Theme;
