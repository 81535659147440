import { v4 as uuidv4 } from "uuid";
// import {
//     calendarEvents,
//     birthdayEvents,
//     holidayEvents,
//     discoveredEvents,
//     meetupEvents,
//     otherEvents,
// } from "@doar/shared/data/events";
import { IEvent } from "@doar/shared/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endpoints, fetchWrapper } from "../../util/api";
import { getAccessToken } from "../../util/auth";
import dayjs from "dayjs";
import { formatEventsToSource } from "../../util/event";

// Define a type for the slice state

export type EventDetails = {
    _id: string;
    evt_name: string;
    evt_desc: string;
    evt_maktid: string;
    evt_currency: string;
    evt_country: string;
    evt_starttime: string;
    evt_endtime: string;
    evt_status: string;
    evt_duration: string;
    evt_previous: string;
    evt_estimate: string;
    evt_actual: string;
    evt_change: string;
    evt_impact: string;
    evt_changePercentage: string;
    evt_unit: string;
    evt_addtimestamp: string;
    evt_source: string;
    evt_sf1: string;
    __v: number;
};
export interface EvenState {
    allEvents: EventDetails[];
    eventSources: IEvent[];
}

// // Define the initial state using that type
// const initialState: EvenState = {
//     eventSources: [
//         calendarEvents,
//         birthdayEvents,
//         holidayEvents,
//         discoveredEvents,
//         meetupEvents,
//         otherEvents,
//     ],
// };

const curYear: string = dayjs().format("YYYY");
const curMonth: string = dayjs().format("MM");

const calendarEvents: IEvent = {
    category: "calendar",
    backgroundColor: "#d9e8ff",
    borderColor: "#0168fa",
    events: [
        {
            id: uuidv4(),
            start: `${curYear}-${curMonth}-08T08:30:00`,
            end: `${curYear}-${curMonth}-08T13:00:00`,
            title: "ThemeForest Meetup",
            description:
                "In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis az pede mollis...",
            currency: "test",
            country: "test",
            previous: "test",
            estimate: "test",
            actual: "test",
            change: "test",
            impact: "test",
            changePrecentage: "test",
        },
    ],
};

const initialState: EvenState = {
    allEvents: [],
    eventSources: [calendarEvents],
};

interface IEventState {
    id: string;
    title: string;
    start: string;
    end: string;
    category: string;
    description?: string;
}

export const fetchEvents: any = createAsyncThunk(
    "dashboard/fetchEvents",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.all_events, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: EventDetails[] = await response.json();
            return data;
        }
    }
);

const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        createEvent: {
            reducer: (state, action: PayloadAction<IEventState>) => {
                const {
                    payload: { category, ...data },
                } = action;
                const source = state.eventSources.find(
                    (src) => src.category === category
                );

                source?.events.push(data);
            },
            prepare: (
                title: string,
                startDate: string,
                endDate: string,
                category: string,
                startTime?: string,
                endTime?: string,
                description?: string
            ) => {
                const start = startTime
                    ? `${startDate}T${startTime}:00`
                    : startDate;
                const end = endTime ? `${endDate}T${endTime}:00` : endDate;
                const data = {
                    id: uuidv4(),
                    title,
                    start,
                    end,
                    description,
                    category,
                };
                return { payload: { ...data } };
            },
        },
        editEvent: {
            reducer: (state, action: PayloadAction<IEventState>) => {
                const {
                    payload: { category, ...data },
                } = action;
                const source = state.eventSources.find(
                    (src) => src.category === category
                );
                if (source) {
                    const index = source.events.findIndex(
                        (el) => el.id === data.id
                    );
                    if (index !== undefined && index >= 0) {
                        source.events[index] = data;
                    }
                }
            },
            prepare: (
                id,
                title: string,
                startDate: string,
                endDate: string,
                category: string,
                startTime?: string,
                endTime?: string,
                description?: string
            ) => {
                const start = startTime
                    ? `${startDate}T${startTime}:00`
                    : startDate;
                const end = endTime ? `${endDate}T${endTime}:00` : endDate;
                const data = {
                    category,
                    id,
                    title,
                    start,
                    end,
                    description,
                };
                return { payload: { ...data } };
            },
        },
        deleteEvent: (
            state,
            action: PayloadAction<{ id: string; category: string }>
        ) => {
            const {
                payload: { id, category },
            } = action;
            const source = state.eventSources.find(
                (src) => src.category === category
            );
            if (source) {
                const index = source.events.findIndex((el) => el.id === id);
                if (index !== undefined && index >= 0) {
                    source.events.splice(index, 1);
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchEvents.fulfilled,
            (state, action: PayloadAction<EventDetails[]>) => {
                if (action.payload?.length > 0) {
                    state.allEvents = action.payload;

                    // format json and save to event soruces
                    state.eventSources[0].events = formatEventsToSource(
                        action.payload
                    );
                }
            }
        );
    },
});

export const { createEvent, editEvent, deleteEvent } = eventSlice.actions;

export default eventSlice.reducer;
