import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endpoints, fetchWrapper } from "../../util/api";
import {
    getAccessToken,
    TUserData,
    PlanDetails,
    setUserData,
} from "../../util/auth";
import { TPlanData } from "../../util/plans";

export interface UserState {
    userData: TUserData;
    currentPlanDetails: PlanDetails;
    allPlans: TPlanData[];
    allForexInstruments: string[];
    selectedInstruments: string[];
    paymentClientSecr: string;
}

export const currentPlanDetailsInitial = {
    planId: "",
    desc: "",
    price: "",
    title: "",
    priceId: "",
    plnInsCount: 0,
};

const initialState: UserState = {
    userData: {
        _id: "",
        cus_username: "",
        cus_name: "",
        cus_email: "",
        cus_password: "",
        cus_country: "",
        cus_isactive: false,
        cus_ispaid: false,
        cus_isemailconfirm: false,
        cus_currpackage: "",
        cus_currpkgprice: "",
        cus_currpkgtrxid: "",
        cus_prevpackage: null,
        cus_prevpkgprice: null,
        cus_lastbilledamt: "",
        cus_nextbilldate: null,
        cus_assins: [],
        cus_joindate: "",
        cus_secquestion1: null,
        cus_secanswer1: null,
        cus_secquestion2: null,
        cus_secanswer2: null,
        cus_affiliate: null,
        cus_lastlogindt: null,
        cus_lastloginip: null,
        cus_token: null,
        cus_note: null,
        __v: 0,
        cus_stripesubid: null,
        cus_lastbilleddate: "",
        cus_assmkt: [],
        cus_dashset: {
            rfrffrfrf: "",
        },
    },
    currentPlanDetails: {
        planId: "",
        desc: "",
        price: "",
        title: "",
        priceId: "",
        plnInsCount: 0,
    },
    allPlans: [],
    allForexInstruments: [],
    selectedInstruments: [],
    paymentClientSecr: "",
};

export const fetchCustomerData: any = createAsyncThunk(
    "userdata/fetch",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.get_customer_data, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const fetchPlans: any = createAsyncThunk(
    "userdata/fetchPlans",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.get_plans, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const fetchAllForexIns: any = createAsyncThunk(
    "userdata/fetchAllForexIns",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(
            endpoints.get_all_forex_instruments,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
            }
        );
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const resetPassword: any = createAsyncThunk(
    "userdata/resetPassword",
    async (inputEmail: string) => {
        const response = await fetchWrapper(endpoints.password_generation, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: {
                emailid: inputEmail,
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const checkResetPasswordToken: any = createAsyncThunk(
    "userdata/checkResetPasswordToken",
    async (resetToken: string) => {
        const response = await fetchWrapper(endpoints.password_reset_check, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: {
                tokenid: resetToken,
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const confirmEmailCheck: any = createAsyncThunk(
    "userdata/confirmEmailCheck",
    async (checkToken: string) => {
        const response = await fetchWrapper(endpoints.confirm_email, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: {
                token: checkToken,
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export type SubmitNewPasswordBody = {
    resettoken: string;
    newpassword: string;
};

export const submitNewPasword: any = createAsyncThunk(
    "userdata/submitNewPasword",
    async (input: SubmitNewPasswordBody) => {
        const response = await fetchWrapper(endpoints.password_reset, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: {
                resettoken: input.resettoken,
                newpassword: input.newpassword,
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const updateInstruments: any = createAsyncThunk(
    "customer/insupdate",
    async (input: string[]) => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.ins_update, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: {
                markets: "1",
                instruments: input?.join(","),
            },
        });
        if (response.ok) {
            const data = response.json();
            return data;
        }
    }
);

export const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDataToStore: (state, action: PayloadAction<TUserData>) => {
            state.userData = action.payload;
        },
        setPricingPlan: (state, action: PayloadAction<PlanDetails>) => {
            state.currentPlanDetails = action.payload;
        },
        setPaymentStatus: (state, action: PayloadAction<boolean>) => {
            state.userData.cus_ispaid = action.payload;
        },
        setSelectedInstruments: (state, action: PayloadAction<string[]>) => {
            state.selectedInstruments = action.payload;
        },
        SetPaymentClientSecr: (state, action: PayloadAction<string>) => {
            state.paymentClientSecr = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchCustomerData.fulfilled,
            (state, action: PayloadAction<TUserData>) => {
                console.log("fetchCustomerData.fulfilled");
                state.userData = action.payload;
                setUserData(action.payload);
            }
        );

        builder.addCase(
            fetchPlans.fulfilled,
            (state, action: PayloadAction<TPlanData[]>) => {
                if (action.payload.length > 0) {
                    state.allPlans = action.payload;
                }
            }
        );

        builder.addCase(
            fetchAllForexIns.fulfilled,
            (state, action: PayloadAction<string[]>) => {
                state.allForexInstruments = action.payload;
            }
        );

        builder.addCase(updateInstruments.fulfilled, () => {
            window.location.href = "/dashboard";
        });

        builder.addCase(resetPassword.fulfilled, (_, action) => {
            console.info(
                "PASSWORD RESET  EMAIL SENT RESPONSE >>> ",
                action.payload
            );
        });

        builder.addCase(checkResetPasswordToken.fulfilled, (_, action) => {
            console.info(
                "PASSWORD RESET TOKEN CHECK - RESPONSE >>> ",
                action.payload
            );
        });

        builder.addCase(confirmEmailCheck.fulfilled, (_, action) => {
            console.info("CHECK EMAIL CONFIRM - RESPONSE >>> ", action.payload);
        });

        builder.addCase(submitNewPasword.fulfilled, (_, action) => {
            console.info("PASSWORD RESET SUCCESSFULLY >>> ", action.payload);
        });
    },
});

export const {
    setUserDataToStore,
    setPricingPlan,
    setPaymentStatus,
    setSelectedInstruments,
    SetPaymentClientSecr,
} = UserSlice.actions;
export default UserSlice.reducer;
