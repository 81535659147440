/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

export type TUserAuth = {
    accessToken: string;
    refreshToken: string;
};

export type TUserDataArr = [TUserData];

export interface PlanDetails {
    planId: string;
    priceId: string;
    title: string;
    desc: string;
    price: string;
    plnInsCount: number;
    plnIsTrial?: string;
}

export type TUserData = {
    _id: string;
    cus_username: string;
    cus_name: string;
    cus_email: string;
    cus_password: string;
    cus_country: string;
    cus_isactive: boolean;
    cus_ispaid: boolean;
    cus_isemailconfirm: boolean;
    cus_currpackage: string;
    cus_currpkgprice: string;
    cus_currpkgtrxid: string;
    cus_prevpackage: string | null;
    cus_prevpkgprice: string | null;
    cus_lastbilledamt: string;
    cus_nextbilldate: string | null;
    cus_assins: string[];
    cus_joindate: string;
    cus_secquestion1: string | null;
    cus_secanswer1: string | null;
    cus_secquestion2: string | null;
    cus_secanswer2: string | null;
    cus_affiliate: string | null;
    cus_lastlogindt: string | null;
    cus_lastloginip: string | null;
    cus_token: string | null;
    cus_note: string | null;
    cus_stripesubid: string | null;
    __v: number;
    cus_lastbilleddate: string;
    cus_assmkt: string[];
    cus_dashset: {
        [key: string]: string;
    };
};

export const useAuth = () => useContext(AuthContext);

export const getUserAuthData = () => {
    if (typeof Storage === "undefined") return {};
    return JSON.parse(localStorage.getItem("auth") || "{}");
};

export const setUserAuthData = (user: Partial<TUserAuth>) => {
    if (user?.constructor.name !== "Object") {
        throw new Error("No valid data found");
    }
    if (Object.keys(user)?.length === 0) {
        throw new Error("No data found");
    }
    if (typeof Storage === "undefined") {
        throw new Error("No valid storage type found");
    }
    localStorage.setItem("auth", JSON.stringify(user));
};

export const setUserData = (userData: Partial<TUserData>) => {
    if (userData.constructor.name !== "Object") {
        throw new Error("No valid data found");
    }
    if (Object.keys(userData)?.length === 0) {
        throw new Error("No data found");
    }
    if (typeof Storage === "undefined") {
        throw new Error("No valid storage type found");
    }
    localStorage.setItem("userData", JSON.stringify(userData));
};

export function clearUserData(): void {
    if (typeof Storage === "undefined") return;
    localStorage.removeItem("auth");
    localStorage.removeItem("userData");
}

export const getRefreshToken = () => {
    if (typeof Storage === "undefined") return false;
    return JSON.parse(localStorage.getItem("auth") || "{}")?.refreshToken;
};

export const getAccessToken = () => {
    if (typeof Storage === "undefined") {
        return new Error("Storage type not valid");
    }
    return JSON.parse(localStorage.getItem("auth") || "{}")?.accessToken;
};

export const updateAccessToken = (token: string): void => {
    if (typeof Storage === "undefined") return;
    const user = JSON.parse(localStorage.getItem("auth") || "{}");
    user.accessToken = token;
    localStorage.setItem("auth", JSON.stringify(user));
};

export const isAuthenticated = () => {
    const accessToken = getAccessToken();
    if (!accessToken) return false;
    return true;
};

export function getPayloadFromToken(token: string) {
    if (!token) {
        return {};
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
}
