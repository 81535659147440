import { ToastContainer, toast, ToastContent } from "react-toastify";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import { StyledChildrenContainer } from "./style";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "./../redux/store";
import useSound from "use-sound";
// import notification from "./audio/notification.wav";
import { PUBLIC_PAGES, PUBLIC_PAGES_NOT_VISIBLE_HEADER } from "../util/consts";
interface IProps {
    children: React.ReactNode;
    hasSidebar?: boolean;
    hideHeader?: boolean;
    hideFooter?: boolean;
    sidebarLayout?: 1 | 2;
    layout?: "app";
}

// const SOCKET_SERVER_URL = "https://engine.m5fx.com";
const SOCKET_SERVER_URL = process.env.REACT_APP_BACKEND_BASE_URL || "";

const Layout = ({
    children,
    hasSidebar,
    hideHeader,
    hideFooter,
    sidebarLayout,
}: IProps) => {
    const location = useLocation();
    const [play] = useSound(
        "https://staticimp.m5fx.com/audio/notifym5fx.wav"
        // notification
    );

    const loggedInUser = useSelector((state: RootState) => state.user);

    // Check if the pathname of the current location contains "/signin"
    const isPublicPages =
        PUBLIC_PAGES.some((page) => location.pathname.includes(page)) ||
        location.pathname === "/";

    const isHeaderNotVisiblePages = PUBLIC_PAGES_NOT_VISIBLE_HEADER.some(
        (page) => location.pathname.includes(page)
    );

    useEffect(() => {
        const cusDashset = loggedInUser.userData.cus_dashset;
        const playSound = cusDashset?.soundnotify === "true";
        if (!isPublicPages) {
            const socket = io(SOCKET_SERVER_URL);
            socket.on("connect", () => {
                console.info("Connected to server");
            });

            socket.on("disconnect", () => {
                console.info("Disconnected from server");
            });

            socket.on("notification", (message) => {
                const toastMessage: ToastContent = message;
                if (playSound) play();
                toast.success(toastMessage, { autoClose: 4000 });
            });

            socket.on("newsnotification", (message) => {
                const toastMessage: ToastContent = message;
                if (cusDashset?.newsnotify === "true") {
                    if (playSound) play();
                    toast.info(toastMessage, { autoClose: 4000 });
                }
            });

            socket.on("exchangenotification", (message) => {
                const toastMessage: ToastContent = message;
                if (cusDashset?.exchangenotify === "true") {
                    if (playSound) play();
                    toast.warning(toastMessage, { autoClose: 4000 });
                }
            });

            socket.on("eventnotification", (message) => {
                const toastMessage: ToastContent = message;
                if (cusDashset?.eventnotify === "true") {
                    if (playSound) play();
                    toast.success(toastMessage, { autoClose: 4000 });
                }
            });

            socket.on("m5fxcalcnotification", (message) => {
                const toastMessage: ToastContent = message.message;
                if (
                    cusDashset?.tradingnotify === "true" &&
                    loggedInUser?.userData?.cus_assins?.includes(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        message.instrument
                    )
                ) {
                    if (playSound) play();
                    toast.success(toastMessage, { autoClose: 4000 });
                }
            });

            return () => {
                socket.disconnect();
            };
        }
    }, [loggedInUser]);

    return (
        <>
            {!hideHeader && !isHeaderNotVisiblePages && (
                <Header hasSidebar={hasSidebar} sidebarLayout={sidebarLayout} />
            )}
            <StyledChildrenContainer>
                <div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
                {children}
            </StyledChildrenContainer>
            {!hideFooter && <Footer />}
        </>
    );
};

Layout.defaultProps = {
    hideHeader: false,
    hideFooter: false,
};

export default Layout;
