import ReactCalendar from "react-calendar";
import { StyledWrap } from "./style";
import moment from "moment";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { EventDetails } from "../../redux/slices/event";

interface IProps {
    className?: string;
    value?: Date | Date[] | null | undefined;
    onChange?: (date: Date | Date[]) => void;
}

export function ConvertDate(dateStr: string): string {
    // Parse the input date string
    const inputDate = new Date(dateStr);

    // Format the date as "DD-MM-YYYY"
    const formattedDate =
        inputDate.getDate().toString().padStart(2, "0") +
        "-" +
        (inputDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        inputDate.getFullYear().toString();

    return formattedDate;
}

const Calendar = ({ className, value, onChange }: IProps) => {
    const eventData = useSelector((state: RootState) => state.events);
    const formatShortWeekday = (locale: string, date: Date): string => {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const d = new Date(date);
        const dayName = days[d.getDay()].substring(0, 2);
        return dayName;
    };
    const formatDay = (locale: string, date: Date): string => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, "0");
        return day;
    };
    const classes = className ? `${className} calendar` : "calendar";

    // const markedDates = ["04-04-2024", "06-04-2024", "15-04-2024"];

    const markedDates = eventData?.allEvents
        ?.map((item: EventDetails) => ConvertDate(item.evt_starttime))
        .filter(
            (date, index, self) => index === self.findIndex((d) => d === date)
        );

    return (
        <StyledWrap className={classes}>
            <ReactCalendar
                value={value}
                onChange={onChange}
                calendarType="US"
                prevLabel={<i className="fa fa-chevron-left" />}
                nextLabel={<i className="fa fa-chevron-right" />}
                formatShortWeekday={formatShortWeekday}
                formatDay={formatDay}
                tileClassName={({ date }) => {
                    if (
                        markedDates?.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                        )
                    ) {
                        return "highlight";
                    }
                    return ""; // Ensure to return a value in all cases
                }}
            />
        </StyledWrap>
    );
};

export default Calendar;
