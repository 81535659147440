import { EventDetails } from "../redux/slices/event";
import { v4 as uuidv4 } from "uuid";

export function formatEventsToSource(allEvents: EventDetails[]) {
    return allEvents.map((event: EventDetails) => {
        // Parse evt_starttime and evt_endtime
        const startDateTime = new Date(event.evt_starttime);
        const endDateTime = new Date(event.evt_endtime);

        const startTimeFormatted = `${startDateTime.getFullYear()}-${(
            startDateTime.getMonth() + 1
        )
            .toString()
            .padStart(2, "0")}-${startDateTime
            .getDate()
            .toString()
            .padStart(2, "0")}T${startDateTime
            .getHours()
            .toString()
            .padStart(2, "0")}:${startDateTime
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${startDateTime
            .getSeconds()
            .toString()
            .padStart(2, "0")}`;
        const endTimeFormatted = `${endDateTime.getFullYear()}-${(
            endDateTime.getMonth() + 1
        )
            .toString()
            .padStart(2, "0")}-${endDateTime
            .getDate()
            .toString()
            .padStart(2, "0")}T${endDateTime
            .getHours()
            .toString()
            .padStart(2, "0")}:${endDateTime
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${endDateTime
            .getSeconds()
            .toString()
            .padStart(2, "0")}`;

        return {
            id: uuidv4(),
            start: startTimeFormatted,
            end: endTimeFormatted,
            title: event.evt_name,
            description: event.evt_desc || "",
            currency: event.evt_currency,
            country: event.evt_country,
            previous: event.evt_previous,
            estimate: event.evt_estimate,
            actual: event.evt_actual,
            change: event.evt_change,
            impact: event.evt_impact,
            changePrecentage: event.evt_changePercentage,
        };
    });
}
