import { useState, useEffect } from "react";
import { Settings } from "react-feather";
import { Row, Col, Checkbox, Label } from "@doar/components";
import { TTheme } from "@doar/shared/types";
import { SkinModes } from "./data";
import Mode from "./mode";
import {
    StyledSettings,
    StyledSettingsBtn,
    StyledSettingsBody,
    StyledCard,
    StyledLogo,
    StyledNote,
    StyledLabel,
    StyledNavLink,
} from "./style";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../redux/store";
import { TUserData, getAccessToken, setUserData } from "./../../util/auth";
import { endpoints, fetchWrapper } from "./../../util/api";
import { setUserDataToStore } from "./../../redux/slices/userSlice";
import ToggleSwitch from "./toggle-switch/ToggleSwitch";

interface IProps {
    themeHandler: (el: TTheme) => void;
    curTheme: TTheme;
}

const SettingsCard = ({ themeHandler, curTheme }: IProps) => {
    const [show, setShow] = useState(false);
    const [location, setLocation] = useState("");
    useEffect(() => {
        setLocation(window.location.origin);
    }, []);

    const dispatch = useDispatch();

    const loggedInUser = useSelector((state: RootState) => state.user);
    const cusDashset = loggedInUser.userData.cus_dashset;

    const [newsnotify, setNewsnotify] = useState(
        cusDashset?.newsnotify === "true"
    );
    const [eventnotify, setEventnotify] = useState(
        cusDashset?.eventnotify === "true"
    );
    const [exchangenotify, setExchangenotify] = useState(
        cusDashset?.exchangenotify === "true"
    );
    const [tradingnotify, setTradingnotify] = useState(
        cusDashset?.tradingnotify === "true"
    );
    const [soundnotify, setSoundnotify] = useState(
        cusDashset?.soundnotify === "true"
    );

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const updateSettings = async () => {
            const accessToken: string = getAccessToken();
            try {
                const response = await fetchWrapper(
                    endpoints.update_dash_settings,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + accessToken,
                        },
                        body: {
                            newsnotify: String(newsnotify),
                            eventnotify: String(eventnotify),
                            exchangenotify: String(exchangenotify),
                            tradingnotify: String(tradingnotify),
                            soundnotify: String(soundnotify),
                        },
                    }
                );
                if (typeof accessToken !== "string") {
                    throw new Error("accessToken must be a string");
                }
                if (response.ok) {
                    const userResponse = await fetchWrapper(
                        endpoints.get_customer_data,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    if (userResponse.ok) {
                        const res: TUserData = await userResponse.json();
                        if (res) {
                            setUserData(res);
                            dispatch(setUserDataToStore(res));
                        }
                    }
                } else {
                    console.error("User profile update failed");
                }
            } catch (error) {
                console.error(
                    "An error occurred during updating profile:",
                    error
                );
            }
            setDisabled(false);
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-unused-expressions
        disabled && updateSettings();
    }, [newsnotify, eventnotify, exchangenotify, tradingnotify, soundnotify]);

    const onChangeNewsnotify = () => {
        setDisabled(true);
        setNewsnotify(!newsnotify);
    };

    const onChangeExchangenotify = () => {
        setDisabled(true);
        setExchangenotify(!exchangenotify);
    };

    const onChangeEventnotify = () => {
        setDisabled(true);
        setEventnotify(!eventnotify);
    };

    const onChangeCalcnotify = () => {
        setDisabled(true);
        setTradingnotify(!tradingnotify);
    };

    const onChangeSound = () => {
        setDisabled(true);
        setSoundnotify(!soundnotify);
    };

    return (
        <StyledSettings $show={show}>
            <StyledSettingsBtn
                $show={show}
                onClick={() => setShow((prev) => !prev)}
            >
                <Settings width={20} height={20} />
            </StyledSettingsBtn>
            <StyledSettingsBody>
                <StyledCard>
                    <StyledLogo>
                        M5<span>F</span>X
                    </StyledLogo>
                    <StyledLabel></StyledLabel>
                    <StyledLabel>Change your app settings here</StyledLabel>
                </StyledCard>

                <StyledCard $hasBorder>
                    <StyledLabel>Skin Mode</StyledLabel>
                    <Row gutters={10}>
                        {SkinModes.map((el, i) => (
                            <Col xs={4} sm={4} key={el} mt={i > 2 ? "10px" : 0}>
                                <Mode
                                    mode={el}
                                    onClick={() => themeHandler(el)}
                                    active={curTheme === el}
                                />
                            </Col>
                        ))}
                    </Row>
                </StyledCard>

                <StyledCard $hasBorder>
                    <StyledLabel>Notifications</StyledLabel>
                    {/* <Row gutters={10}>
                        <Col>
                            <Checkbox
                                id="newsnotification"
                                name="newsnotification"
                                label="News Notification"
                                checked={newsnotify}
                                onClick={onChangeNewsnotify}
                                disabled={disabled}
                            />
                            <Checkbox
                                id="exchangenotification"
                                name="exchangenotification"
                                label="Exchange Notification"
                                checked={exchangenotify}
                                onClick={onChangeExchangenotify}
                                disabled={disabled}
                            />
                            <Checkbox
                                id="eventnotification"
                                name="eventnotification"
                                label="Event Notification"
                                checked={eventnotify}
                                onClick={onChangeEventnotify}
                                disabled={disabled}
                            />
                        </Col>
                    </Row> */}
                    <Row gutters={10} mb={"8px"}>
                        <ToggleSwitch
                            label="newsnotification"
                            isChecked={newsnotify}
                            onChange={onChangeNewsnotify}
                            disabled={disabled}
                        />
                        <Label htmlFor="newsnotification">
                            News Notification
                        </Label>
                    </Row>
                    <Row gutters={10} mb={"8px"}>
                        <ToggleSwitch
                            label="exchangenotification"
                            isChecked={exchangenotify}
                            onChange={onChangeExchangenotify}
                            disabled={disabled}
                        />
                        <Label htmlFor="exchangenotification">
                            Exchange Notification
                        </Label>
                    </Row>
                    {/* <Row gutters={10} mb={"8px"}>
                        <ToggleSwitch
                            label="eventnotification"
                            isChecked={eventnotify}
                            onChange={onChangeEventnotify}
                            disabled={disabled}
                        />
                        <Label htmlFor="eventnotification">
                            Event Notification
                        </Label>
                    </Row> */}
                    <Row gutters={10}>
                        <ToggleSwitch
                            label="calcnotification"
                            isChecked={tradingnotify}
                            onChange={onChangeCalcnotify}
                            disabled={disabled}
                        />
                        <Label htmlFor="eventnotification">
                            Trading Notification
                        </Label>
                    </Row>
                </StyledCard>

                <StyledCard $hasBorder>
                    <StyledLabel>Sounds Control</StyledLabel>
                    {/* <Checkbox
                        id="notificationsound"
                        name="notificationsound"
                        label="Notification Sound"
                        checked={soundnotify}
                        onClick={onChangeSound}
                        disabled={disabled}
                    /> */}
                    <Row gutters={10}>
                        <ToggleSwitch
                            label="notificationsound"
                            isChecked={soundnotify}
                            onChange={onChangeSound}
                            disabled={disabled}
                        />
                        <Label htmlFor="notificationsound">
                            Notification Sound
                        </Label>
                    </Row>
                </StyledCard>

                <div className="pd-y-20 bd-t" style={{ display: "none" }}>
                    <h4 className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                        Navigation Skin
                    </h4>
                    <div className="row row-xs">
                        <div className="col-4">
                            <a
                                href="/"
                                className="df-skin df-skin-default active"
                                data-title="default"
                            >
                                df
                            </a>
                            <span className="df-skin-name">Default</span>
                        </div>
                        <div className="col-4">
                            <a
                                href="/"
                                className="df-skin df-skin-deepblue"
                                data-title="deepblue"
                            >
                                <span />
                                <span />
                            </a>
                            <span className="df-skin-name">Deep Blue</span>
                        </div>
                        <div className="col-4">
                            <a
                                href="/"
                                className="df-skin df-skin-charcoal"
                                data-title="charcoal"
                            >
                                <span />
                                <span />
                            </a>
                            <span className="df-skin-name">Charcoal</span>
                        </div>
                        <div className="col-4 mg-t-15">
                            <a
                                href="/"
                                className="df-skin df-skin-gradient1"
                                data-title="gradient1"
                            >
                                <span />
                                <span />
                            </a>
                            <span className="df-skin-name">Gradient 1</span>
                        </div>
                    </div>
                </div>

                <StyledCard $hasBorder>
                    {/* <StyledLabel>Navigation Layout</StyledLabel>
                    <Row gutters={10}>
                        <Col xs={6} sm={6}>
                            <StyledNavLink href={`${location}/`}>
                                Horizontal
                            </StyledNavLink>
                        </Col>
                        <Col xs={6} sm={6}>
                            <StyledNavLink
                                href={`${location}/classic-plus/dashboard-one`}
                            >
                                Vertical
                            </StyledNavLink>
                        </Col>
                    </Row> */}
                </StyledCard>

                <div className="pd-y-20 bd-t" style={{ display: "none" }}>
                    <h4 className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                        Font Family Base
                    </h4>
                    <div className="row row-xs">
                        <div className="col-7">
                            <a
                                href="/"
                                id="setFontBase"
                                className="btn btn-xs btn-outline-secondary active"
                            >
                                IBM Plex Sans
                            </a>
                        </div>
                        <div className="col-5">
                            <a
                                href="/"
                                id="setFontRoboto"
                                className="btn btn-xs btn-outline-secondary"
                            >
                                Roboto
                            </a>
                        </div>
                    </div>
                </div>
            </StyledSettingsBody>
        </StyledSettings>
    );
};

export default SettingsCard;
