import { StyledLogo } from "./style";

const AsideLogo = () => {
    return (
        <StyledLogo className="aside-logo" path="/">
            M5<span>F</span>X
        </StyledLogo>
    );
};

export default AsideLogo;
