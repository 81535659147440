import { Heart } from "react-feather";
import {
    StyledFooter,
    StyledFooterLeft,
    StyledFooterRight,
    StyledFooterNav,
    StyledFotterNavLink,
} from "./style";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { Badge } from "@doar/components";
import { PUBLIC_PAGES } from "../../util/consts";

// const SOCKET_SERVER_URL = "https://engine.m5fx.com";
const SOCKET_SERVER_URL = process.env.REACT_APP_BACKEND_BASE_URL || "";

const Footer = () => {
    const isPublicPages = PUBLIC_PAGES.some((page) =>
        location.pathname.includes(page)
    );
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (!isPublicPages) {
            const socket = io(SOCKET_SERVER_URL);
            socket.on("connect", () => {
                console.info("Connected to server");
                setIsConnected(true);
            });

            socket.on("disconnect", () => {
                console.info("Disconnected from server");
                setIsConnected(false);
            });

            return () => {
                socket.disconnect();
            };
        }
    }, [isPublicPages]);

    return (
        <StyledFooter>
            <StyledFooterLeft>
                <span>&copy; M5FX Inc {new Date().getFullYear()} </span>
                <span className="copright-link">
                    MADE WITH <Heart size="24" /> BY{"  "}
                    <a
                        href="https://www.m5fx.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        M5FX
                    </a>
                </span>
            </StyledFooterLeft>
            <StyledFooterRight>
                <StyledFooterNav>
                    <StyledFotterNavLink path="/help">
                        {!isPublicPages && (
                            <Badge
                                color={isConnected ? "success" : "danger"}
                                shape="rounded"
                                ml="auto"
                            >
                                {isConnected ? "CONNECTED" : "DISCONNECTED"}
                            </Badge>
                        )}
                    </StyledFotterNavLink>
                </StyledFooterNav>
            </StyledFooterRight>
        </StyledFooter>
    );
};

export default Footer;
