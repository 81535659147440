import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endpoints, fetchWrapper } from "../../util/api";
import { getAccessToken } from "../../util/auth";

export interface TechState {
    customerIns: string[];
    selectedCustomerInsId: string;
    firstCustomerIns: string;
    dailySentimentsSingle: DailySentimentsFstDetails | null;
    newsListOfIns: NewsDetails[];
    fxStateTechData: FxStataTechResObj[];
    fetchM5fxCalcData: CalcM5fxResp | null;
    lockNavigateInstrumentPage: boolean;
    indicatorEMA: IndicatorsResponseBody | null;
    indicatorRSA: IndicatorsResponseBody | null;
    indicatorMACD: IndicatorsResponseBody | null;
    indicatorATR: IndicatorsResponseBody | null;
}

export type NewsDetails = {
    _id: string;
    nws_title: string;
    nws_txt: string;
    nws_source: string;
    nws_url: string;
    nws_bannerurl: string;
    nws_posteddt: string;
    nws_mktid: string;
    nws_insid: string;
    nws_priority: string;
    nws_tags: string;
    nws_isactive: boolean;
    nws_timestamp: string;
    nws_topics: {
        topic: string;
        relevance_score: string;
    }[];
    nws_ovSentimentscore: string;
    nws_ovSentimentlabel: string;
    nws_tickers: {
        ticker: string;
        relevance_score: string;
        ticker_sentiment_score: string;
        ticker_sentiment_label: string;
    }[];
    nws_sf1: string | null;
    __v: number;
};

export interface DailySentimentsFstDetails {
    _id: string;
    fst_date: string;
    fst_ins: string;
    fst_type: string;
    fst_details: FSTDetails | null;
}

export interface FxStataTechResObj {
    alias: string;
    name: string;
    desc: string;
    lastval: string;
    currval: string;
    change: string;
}

export type ReqBodyForIndicators = {
    indicatorid: string;
    instrumentid: string;
};

export type IndicatorsResponseBody = {
    _id: string;
    inddt_id: string;
    inddt_indname: string;
    inddt_insname: string;
    inddt_lastupdated: string;
    inddt_interval: string;
    inddt_timeperiod: string;
    inddt_details: {
        "1: Symbol": string;
        "2: Indicator": string;
        "3: Last Refreshed": string;
        "4: Interval": string;
        "5: Time Period": number;
        "6: Series Type": string;
        "7: Time Zone": string;
    };
    inddt_data: {
        [key: string]: {
            EMA: string;
        };
    };
    __v: number;
};

export type FSTDetails = {
    name: string;
    shortPercentage: string;
    longPercentage: string;
    shortVolume: string;
    longVolume: string;
    longPositions: string;
    shortPositions: string;
    totalPositions: string;
    avgShortPrice: string;
    avgLongPrice: string;
};

export type CalcM5fxResp = {
    _id: string;
    m5fxcalc_mkid: string;
    m5fxcalc_insid: string;
    m5fxcalc_alias: string;
    m5fxcalc_datetime: string;
    m5fxcalc_rating: string;
    m5fxcalc_bullishval: string;
    m5fxcalc_bearishval: string;
    m5fxcalc_isalert: boolean;
};

const initialState: TechState = {
    customerIns: [],
    selectedCustomerInsId: "EURUSD",
    firstCustomerIns: "",
    dailySentimentsSingle: null,
    newsListOfIns: [],
    fxStateTechData: [],
    fetchM5fxCalcData: null,
    lockNavigateInstrumentPage: false,
    indicatorEMA: null,
    indicatorRSA: null,
    indicatorMACD: null,
    indicatorATR: null,
};

export const fetchCustomerIns: any = createAsyncThunk(
    "dashboard/fetchCustomerIns",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.customer_ins, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: string[] = await response.json();
            return data;
        }
    }
);

export const fetchDailySentimentSingleIns: any = createAsyncThunk(
    "dashboard/fetchDailySentimentSingleIns",
    async (insId: string) => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.single_sentiment_data, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: {
                ins: insId,
            },
        });
        if (response.ok) {
            const data: DailySentimentsFstDetails = await response.json();
            return data;
        }
    }
);

export const indicatorsFetch: any = createAsyncThunk(
    "dashboard/indicatorsFetch",
    async (reqBody: ReqBodyForIndicators) => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.fetch_indicator_data, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: reqBody,
        });
        if (response.ok) {
            const data: IndicatorsResponseBody = await response.json();
            return data;
        }
    }
);

export const fetchFxNewsOfIns: any = createAsyncThunk(
    "dashboard/fetchFxNewsOfIns",
    async (id: string) => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.fx_news_of_ins, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: {
                instrument: id,
            },
        });
        if (response.ok) {
            const data: NewsDetails[] = await response.json();
            return data;
        }
    }
);

export const fetchFxstattec: any = createAsyncThunk(
    "dashboard/fetchFxstattec",
    async (id: string) => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.calculations_fx, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: {
                instrument: id,
            },
        });
        if (response.ok) {
            const data: NewsDetails[] = await response.json();
            return data;
        }
    }
);

export const fetchM5fxCalc: any = createAsyncThunk(
    "dashboard/fetchM5fxCalc",
    async (id: string) => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.calculations_calc_m5fx, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: {
                instrument: id,
            },
        });
        if (response.ok) {
            const data: CalcM5fxResp = await response.json();
            return data;
        }
    }
);

export const techSlice = createSlice({
    name: "tech",
    initialState,
    reducers: {
        setCustomerInsToStore: (state, action: PayloadAction<string[]>) => {
            state.customerIns = action.payload;
        },
        setSelectedCustomerIns: (state, action: PayloadAction<string>) => {
            state.selectedCustomerInsId = action.payload;
        },
        setFirstCustomerIns: (state, action: PayloadAction<string>) => {
            state.firstCustomerIns = action.payload;
        },
        setLockNavigateInstrumentPage: (state, action) => {
            state.lockNavigateInstrumentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchCustomerIns.fulfilled,
            (state, action: PayloadAction<string[]>) => {
                if (action.payload?.length > 0) {
                    state.customerIns = action.payload;

                    // set selected ins from customer ins
                    state.firstCustomerIns = action.payload[0];
                    state.selectedCustomerInsId = action.payload[0];
                }
            }
        );

        builder.addCase(
            fetchDailySentimentSingleIns.fulfilled,
            (state, action) => {
                if (action.payload) {
                    state.dailySentimentsSingle = action.payload;
                }
            }
        );

        builder.addCase(fetchFxNewsOfIns.fulfilled, (state, action) => {
            if (action.payload) {
                state.newsListOfIns = action.payload;
            }
        });

        builder.addCase(
            fetchFxstattec.fulfilled,
            (state, action: PayloadAction<FxStataTechResObj[]>) => {
                if (action.payload) {
                    state.fxStateTechData = action.payload;
                }
            }
        );

        builder.addCase(
            fetchM5fxCalc.fulfilled,
            (state, action: PayloadAction<CalcM5fxResp>) => {
                if (action.payload) {
                    state.fetchM5fxCalcData = action.payload;
                }
            }
        );

        builder.addCase(
            indicatorsFetch.fulfilled,
            (state, action: PayloadAction<IndicatorsResponseBody>) => {
                const indicatorType = action?.payload?.inddt_id;
                if (indicatorType == "EMA") {
                    state.indicatorEMA = action.payload;
                } else if (indicatorType == "RSA") {
                    state.indicatorRSA = action.payload;
                } else if (indicatorType == "MACD") {
                    state.indicatorMACD = action.payload;
                } else if (indicatorType == "ATR") {
                    state.indicatorATR = action.payload;
                }
            }
        );
    },
});

export const {
    setCustomerInsToStore,
    setSelectedCustomerIns,
    setFirstCustomerIns,
    setLockNavigateInstrumentPage,
} = techSlice.actions;
export default techSlice.reducer;
