import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endpoints, fetchWrapper } from "../../util/api";
import { getAccessToken } from "../../util/auth";

export interface DashboardState {
    newsList: NewsDetails[];
    exchangeStatuses: ExchangeStatusData[];
    filteredExchangeStatuses: ExchangeStatusData[];
    dailySentiments: DailySentiments[];
    weeklySentiments: DailySentiments[];
}

export type NewsDetails = {
    _id: string;
    nws_title: string;
    nws_txt: string;
    nws_source: string;
    nws_url: string;
    nws_bannerurl: string;
    nws_posteddt: string;
    nws_mktid: string;
    nws_insid: string;
    nws_priority: string;
    nws_tags: string;
    nws_isactive: boolean;
    nws_timestamp: string;
    nws_topics: {
        topic: string;
        relevance_score: string;
    }[];
    nws_ovSentimentscore: string;
    nws_ovSentimentlabel: string;
    nws_tickers: {
        ticker: string;
        relevance_score: string;
        ticker_sentiment_score: string;
        ticker_sentiment_label: string;
    }[];
    nws_sf1: string | null;
    __v: number;
};

export interface ExchangeStatusData {
    _id: string;
    exc_name: string;
    exc_desc: string;
    exc_location: string;
    exc_currency: string | null;
    exc_opentime: string | null;
    exc_closetime: string | null;
    exc_tzone: string | null;
    exc_currstatus: string;
    exc_mktype: string;
    exc_prmexchanges: string;
    exc_localopen: string;
    exc_localclose: string;
    exc_updatetime: string;
    __v: number;
}
export interface DailySentiments {
    _id: string;
    fst_date: string;
    fst_ins: string;
    fst_type: string;
    fst_details: DailySentimentsFstDetails | null;
}

export interface DailySentimentsFstDetails {
    name: string;
    shortPercentage: string;
    longPercentage: string;
    shortVolume: string;
    longVolume: string;
    longPositions: string;
    shortPositions: string;
    totalPositions: string;
    avgShortPrice: string;
    avgLongPrice: string;
}

const initialState: DashboardState = {
    newsList: [],
    exchangeStatuses: [],
    filteredExchangeStatuses: [],
    dailySentiments: [],
    weeklySentiments: [],
};

export const fetchNewsData: any = createAsyncThunk(
    "dashboard/fetchNewsData",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.news_data, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: NewsDetails[] = await response.json();
            return data;
        }
    }
);

export const fetchExchangeStatuses: any = createAsyncThunk(
    "dashboard/fetchExchangeStatuses",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.exchange_status, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: ExchangeStatusData[] = await response.json();
            return data;
        }
    }
);

export const fetchDailySentiments: any = createAsyncThunk(
    "dashboard/fetchDailySentiments",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.daily_sentiments, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: DailySentiments = await response.json();
            return data;
        }
    }
);

export const fetchWeeklySentiments: any = createAsyncThunk(
    "dashboard/fetchWeeklySentiments",
    async () => {
        const accessToken: string = getAccessToken();
        const response = await fetchWrapper(endpoints.weekly_sentiments, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
        if (response.ok) {
            const data: DailySentiments = await response.json();
            return data;
        }
    }
);

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setNewsDataToStore: (state, action: PayloadAction<NewsDetails[]>) => {
            state.newsList = action.payload;
        },
        setExchangeStatusesByFilter: (
            state,
            action: PayloadAction<ExchangeStatusData[]>
        ) => {
            state.filteredExchangeStatuses = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNewsData.fulfilled, (state, action) => {
            if (action.payload?.length > 0) {
                state.newsList = action.payload;
            }
        });

        builder.addCase(fetchExchangeStatuses.fulfilled, (state, action) => {
            if (action.payload?.length > 0) {
                state.exchangeStatuses = action.payload;

                // reset filter
                state.filteredExchangeStatuses = [];
            }
        });

        builder.addCase(fetchDailySentiments.fulfilled, (state, action) => {
            if (action.payload) {
                state.dailySentiments = action.payload;
            }
        });

        builder.addCase(fetchWeeklySentiments.fulfilled, (state, action) => {
            if (action.payload) {
                state.weeklySentiments = action.payload;
            }
        });
    },
});

export const { setNewsDataToStore, setExchangeStatusesByFilter } =
    dashboardSlice.actions;
export default dashboardSlice.reducer;
