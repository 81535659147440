/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Link } from "react-router-dom";
import { Bell } from "react-feather";
import { DropdownToggle, Dropdown } from "@doar/components";
import Notification from "../dropdown-item";
import {
    StyledDropMenu,
    StyledDropHeader,
    StyledDropItem,
    StyledDropFooter,
    StyledBadge,
} from "../header-dropdown-elements";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { RootState } from "./../../../redux/store";
import { getAccessToken } from "./../../../util/auth";
import { endpoints, fetchWrapper } from "./../../../util/api";

// const SOCKET_SERVER_URL = "https://engine.m5fx.com";
const SOCKET_SERVER_URL = process.env.REACT_APP_BACKEND_BASE_URL || "";

interface NotificationInterface {
    name: string;
    message: string;
    time: string;
    seen: boolean;
}

const NotificationDropdown = () => {
    const [notificationStack, setNotificationStack] = useState<
        NotificationInterface[]
    >([]);

    useEffect(() => {
        const getLastNotifications = async () => {
            const accessToken: string = getAccessToken();
            const response = await fetchWrapper(endpoints.last_notifications, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
            });
            if (response.ok) {
                return response.json();
            }
        };
        getLastNotifications()
            .then((data) => {
                data.map((notification: any) => {
                    setNotificationStack((prevStack) => [
                        ...prevStack,
                        {
                            name: notification.notif_type,
                            message: notification.notif_details,
                            time: new Date(
                                Number(notification.notif_datetime)
                            ).toLocaleString(),
                            seen: false,
                        },
                    ]);
                });
            })
            .catch((e) => console.error(e));
    }, []);

    const loggedInUser = useSelector((state: RootState) => state.user);

    useEffect(() => {
        const socket = io(SOCKET_SERVER_URL);

        socket.on("connect", () => {
            console.info("Connected to server");
        });

        socket.on("notification", (message) => {
            setNotificationStack((prevStack) => [
                {
                    name: "NOTIFICATION",
                    message: message,
                    time: new Date().toLocaleString(),
                    seen: false,
                },
                ...prevStack,
            ]);
        });

        socket.on("newsnotification", (message) => {
            setNotificationStack((prevStack) => [
                {
                    name: "NEWS NOTIFICATION",
                    message: message,
                    time: new Date().toLocaleString(),
                    seen: false,
                },
                ...prevStack,
            ]);
        });

        socket.on("exchangenotification", (message) => {
            setNotificationStack((prevStack) => [
                {
                    name: "EXCHANGE NOTIFICATION",
                    message: message,
                    time: new Date().toLocaleString(),
                    seen: false,
                },
                ...prevStack,
            ]);
        });

        socket.on("eventnotification", (message) => {
            setNotificationStack((prevStack) => [
                {
                    name: "EVENT NOTIFICATION",
                    message: message,
                    time: new Date().toLocaleString(),
                    seen: false,
                },
                ...prevStack,
            ]);
        });

        socket.on("m5fxcalcnotification", (message) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if (
                loggedInUser.userData?.cus_assins?.includes(
                    String(message.instrument)
                )
            ) {
                setNotificationStack((prevStack) => [
                    {
                        name: "TRADING NOTIFICATION",
                        message: message.message,
                        time: new Date().toLocaleString(),
                        seen: false,
                    },
                    ...prevStack,
                ]);
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const unseenNotificationCount = () => {
        let count = 0;

        notificationStack.forEach((e) => {
            if (!e.seen) {
                count++;
            }
        });

        return count;
    };

    const notificationsClicked = () => {
        const seenNotificationStack = notificationStack.map((notification) => ({
            ...notification,
            seen: true,
        }));

        setNotificationStack(seenNotificationStack);
    };

    useEffect(() => {
        const notificationBell = document.querySelector(".notification-bell");

        if (notificationStack.length) {
            notificationBell?.addEventListener("click", notificationsClicked);

            return () =>
                notificationBell?.removeEventListener(
                    "click",
                    notificationsClicked
                );
        }
    }, [notificationStack]);

    return (
        <Dropdown direction="down">
            <DropdownToggle
                variant="texted"
                disabled={notificationStack.length === 0}
                className="notification-bell"
            >
                <Bell className="header-icon" />
                {unseenNotificationCount() > 0 && (
                    <StyledBadge>{unseenNotificationCount()}</StyledBadge>
                )}
            </DropdownToggle>
            <StyledDropMenu>
                <StyledDropHeader>NOTIFICATIONS</StyledDropHeader>
                {notificationStack.map((notification, index) => {
                    return (
                        <StyledDropItem key={index}>
                            <Notification notification={notification} />
                        </StyledDropItem>
                    );
                })}
            </StyledDropMenu>
        </Dropdown>
    );
};

export default NotificationDropdown;
