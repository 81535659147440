export const PUBLIC_PAGES = [
    "/signin",
    "/signup",
    "/pricing",
    "/instrumentselection",
    "/password-reset",
    "/forgot-password",
    "/payment",
];

export const PUBLIC_PAGES_NOT_VISIBLE_HEADER = [];

export const HEADER_PUBLIC = ["/signin", "/signup"];

export const ACCOUNT_SETTINGS_HIDE_PAGES = ["/pricing", "/signup", "/payment"];

export const PROFILE_ICON_HIDE_PAGES = [
    "/forgot-password",
    "/signup",
    "/password-reset",
];
