import "./ToggleSwitch.css";

interface ToggleProps {
    label: string;
    isChecked?: boolean;
    onChange?: any;
    disabled?: boolean;
}

const ToggleSwitch = ({
    label,
    isChecked,
    onChange,
    disabled,
}: ToggleProps) => {
    return (
        <>
            <input
                className="toggleSwitch"
                type="checkbox"
                id={label}
                onChange={onChange}
                checked={isChecked}
                disabled={disabled}
            />
            <label className="toggleLabel" htmlFor={label}>
                {label}
            </label>
        </>
    );
};

export default ToggleSwitch;
