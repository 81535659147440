import React, { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../util/auth";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

interface IProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
    const navigate = useNavigate();
    const { token } = useAuth();
    const location = useLocation();
    const redirectPath = "/signin";
    const userSlice = useSelector((state: RootState) => state.user);
    const techSlice = useSelector((state: RootState) => state.tech);

    useEffect(() => {
        if (!token) {
            // Use the `navigate` function to trigger the redirect
            navigate(redirectPath, {
                replace: true,
                state: { from: location },
            });
        }

        // REDIRECT LOGIC 1
        if (
            !userSlice?.userData?.cus_ispaid &&
            !location.pathname.includes("/pricing") &&
            !location.pathname.includes("/instrumentselection") &&
            !location.pathname.includes("/payment")
        ) {
            navigate("/pricing", {
                replace: true,
                state: { from: location },
            });
        }

        // REDIRECT LOGIC 2
        if (
            userSlice?.currentPlanDetails?.planId != "" &&
            userSlice?.userData?.cus_ispaid &&
            (userSlice?.userData?.cus_assins?.length == 0 ||
                userSlice?.userData?.cus_assins == null) &&
            (userSlice?.userData?.cus_assmkt?.length == 0 ||
                userSlice?.userData?.cus_assmkt == null) &&
            !window.location.pathname?.includes("/instrumentselection") &&
            (userSlice?.userData?.cus_assmkt?.length == 0 ||
                userSlice?.userData?.cus_assmkt == null) &&
            !window.location.pathname?.includes("/dashboard")
        ) {
            window.location.href = "/instrumentselection";
        }
    }, [token, navigate, redirectPath, location]);

    return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
