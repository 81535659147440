import ReactApexChart from "react-apexcharts"; // Import ReactApexChart instead of ApexChart
import { IProps } from "./types";

export const AreaChart = ({ options, series, width, height }: IProps) => {
    return (
        <ReactApexChart // Use ReactApexChart here
            type="area"
            options={options}
            series={series}
            height={height}
            width={width}
        />
    );
};

export default AreaChart;
