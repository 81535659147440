/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { configureStore, combineReducers } from "@reduxjs/toolkit";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import themeReducer from "./slices/theme";
import chatUISlice from "./slices/chat-ui";
import userSlice from "./slices/userSlice";
import dashboardSlice from "./slices/dashboardSlice";
import newsSlice from "./slices/newsSlice";
import techSlice from "./slices/techSlice";
import resetReducer, { resetState } from "./slices/resetSlice";

const persistConfig = {
    key: "doar",
    version: 1.1,
    blacklist: ["ui"],
    storage,
};

const appReducer = combineReducers({
    events: eventReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    theme: themeReducer,
    user: userSlice,
    dashboard: dashboardSlice,
    news: newsSlice,
    tech: techSlice,
    reset: resetReducer,
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === resetState.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
