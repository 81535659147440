/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-unsafe-return */

// export const API_HOST = "https://engine.m5fx.com";
export const API_HOST = process.env.REACT_APP_BACKEND_BASE_URL || "";

export const endpoints = {
    login: "/login",
    logout: "/login/logout",
    signup: "/customer/register",
    token_refresh: "/login/refresh",
    get_customer_data: "/customer/get",
    get_plans: "/customer/getallplans",
    news_data: "/news/newestforex5",
    all_news_data: "/news/allforex",
    exchange_status: "/exchange/exchangestatus",
    daily_sentiments: "/forexdata/dailysentiments",
    create_payment_session: "/customerpayments/createstripesession",
    weekly_sentiments: "/forexdata/weeklysentiments",
    customer_ins: "/customer/getcustomerins",
    single_sentiment_data: "/forexdata/dailysentimentssingleins",
    fx_news_of_ins: "/news/fxnewsofins",
    all_events: "/event/getallevents",
    customer_payment_update: "/customer/paymentupdate",
    update_dash_settings: "/customer/updatedashsettings",
    update_profile: "/customer/updateprofile",
    update_password: "/customer/changepassword",
    get_all_forex_instruments: "/customer/getallforexinstruments",
    ins_update: "/customer/insupdate",
    password_generation: "/login/passwordresetgeneration",
    password_reset_check: "/login/passwordresetcheck",
    cancel_subscription: "/customer/subcriptioncancel",
    password_reset: "/login/passwordreset",
    change_plan: "/customer/changesubplan",
    calculations_fx: "/calculations/fxstattec",
    confirm_email: "/customer/confirmemail",
    fetch_indicator_data: "/indicators/indicatordata",
    calculations_calc_m5fx: "/calculations/m5fxcalc",
    last_notifications: "/calculations/lstfxtrdnotifi",
};

export async function fetchWrapper(
    endpoint: string,
    opts: {
        method: string;
        headers?: { [key: string]: string };
        mode?: string;
        body?: { [key: string]: any } | string;
    }
) {
    opts.headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        ...opts.headers,
    };
    opts.mode = "cors";
    if (opts.body) {
        opts.body = JSON.stringify(opts.body);
    }

    const response = await fetch(`${API_HOST}${endpoint}`, opts as RequestInit);

    if (response.status === 401) {
        // Clear local storage
        localStorage.clear();
        // Redirect to sign-in page or root directory
        window.location.href = "/signin"; // or window.location.href = "/";
    }

    return response;
}
